/* global google */
import React, { useEffect, useState } from 'react';
import { compose, withProps } from 'recompose';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
} from 'react-google-maps';
import InfoBox from 'react-google-maps/lib/components/addons/InfoBox';
import { ReactComponent as HomeMarker } from '../../images/home.svg';
import { findLocationsByBoundingBox } from '../../utils/api';
import { googleMapApiKey } from '../../config';
import mapStyle from './map-style';
import homeMessageIcon from '../../images/home-msg.png';
import homeIcon from '../../images/home-dot.png';
import MarkerList from './MarkerList';

const MyMapComponent = compose(
  withProps({
    containerElement: <div style={{ height: `100vh`, minHeight: '665px' }} />,
    mapElement: <div style={{ minHeight: `100%` }} />,
  }),
  withGoogleMap
)(props => {
  let _map = null;
  const [BB, setBB] = useState({ minLat: 0, maxLat: 0, minLng: 0, maxLng: 0 });

  const [markers, setMarkers] = useState([]);
  const [expandedId, setExpandedId] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const response = await findLocationsByBoundingBox(BB);
      setMarkers(response);
    }

    if (
      !(
        BB.minLat === 0 ||
        BB.maxLat === 0 ||
        BB.minLng === 0 ||
        BB.maxLng === 0
      )
    ) {
      fetchData();
    }
  }, [BB]);

  return (
    <GoogleMap
      ref={map => (_map = map)}
      defaultZoom={17}
      defaultOptions={{
        styles: mapStyle,
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
      onIdle={() => {
        const ne = _map.getBounds().getNorthEast();
        const sw = _map.getBounds().getSouthWest();
        const newBB = {
          minLat: sw.lat(),
          maxLat: ne.lat(),
          minLng: sw.lng(),
          maxLng: ne.lng(),
        };
        if (
          newBB.minLat !== BB.minLat ||
          newBB.maxLat !== BB.maxLat ||
          newBB.minLng !== BB.minLng ||
          newBB.maxLng !== BB.maxLng
        ) {
          setBB(newBB);
        }
      }}
    >
      <Marker
        key="address-center"
        position={new google.maps.LatLng(props.lat, props.lng)}
        onClick={props.onMarkerClick}
        icon={{
          url: homeIcon, // homeIcon,
          animation: google.maps.Animation.DROP,
          anchor: new google.maps.Point(50, 30),
        }}
      >
        <InfoBox
          zIndex={100}
          options={{
            pixelOffset: new google.maps.Size(-118, -79),
            closeBoxURL: ``,
            enableEventPropagation: true,
          }}
        >
          <img src={homeMessageIcon} alt="Hvad er din bolig værd?" />
        </InfoBox>
      </Marker>
      <MarkerList
        data={markers}
        expandedId={expandedId}
        toggleExpandedId={id => {
          if (expandedId === id) {
            setExpandedId(0);
          } else {
            setExpandedId(id);
          }
        }}
      />
    </GoogleMap>
  );
});

const Map = (props: Props) => {
  const { lat, lng, address } = props;

  const handleMarkerClick = ev => {
    // console.log('Marker click event', ev);
  };

  return (
    <MyMapComponent lat={lat} lng={lng} onMarkerClick={handleMarkerClick} />
  );
};

export default Map;
